<template>
  <main>
    <!--
    .########.####.##.....##.########.########.....########.##........#######.....###....########
    .##........##...##...##..##.......##.....##....##.......##.......##.....##...##.##......##...
    .##........##....##.##...##.......##.....##....##.......##.......##.....##..##...##.....##...
    .######....##.....###....######...##.....##....######...##.......##.....##.##.....##....##...
    .##........##....##.##...##.......##.....##....##.......##.......##.....##.#########....##...
    .##........##...##...##..##.......##.....##....##.......##.......##.....##.##.....##....##...
    .##.......####.##.....##.########.########.....##.......########..#######..##.....##....##...
    -->
    <!-- portrait -->
    <div
      v-if="false"
      class="fixed lg:hidden"
      style="top: 80px; right: 10px; z-index: 999"
    >
      <nuxt-link to="/order-subscribe/index">
        <img
          src="https://d1jjjfa1mlyr2v.cloudfront.net/c/home/landscape-01.png"
          class=""
          style="width: 90px"
        >
      </nuxt-link>
    </div>

    <!-- landscape -->
    <div
      v-if="false"
      class="fixed hidden lg:block"
      style="top: 100px; right: 40px; z-index: 999"
    >
      <nuxt-link to="/order-subscribe/index">
        <img
          src="https://d1jjjfa1mlyr2v.cloudfront.net/c/home/landscape-01.png"
          class=""
          style="width: 120px"
        >
      </nuxt-link>
    </div>

    <!--
    .########.....###....##....##.##....##.########.########.
    .##.....##...##.##...###...##.###...##.##.......##.....##
    .##.....##..##...##..####..##.####..##.##.......##.....##
    .########..##.....##.##.##.##.##.##.##.######...########.
    .##.....##.#########.##..####.##..####.##.......##...##..
    .##.....##.##.....##.##...###.##...###.##.......##....##.
    .########..##.....##.##....##.##....##.########.##.....##
    -->
    <header class="relative text-center mb-2">
      <div
        class="landscape:hidden"
        style="padding-bottom: calc(100% / 1600 * 2800)"
      />
      <div
        class="portrait:hidden"
        style="padding-bottom: calc(100% / 4252 * 2240)"
      />

      <template v-for="(x, i) of itemsBanner">
        <nuxt-link
          v-if="!x.isExternal"
          :key="i"
          :to="x.url"
          class="transition-05s"
          :class="{
            'opacity-0 pointer-events-none': indexBanner != i,
            'opacity-100 pointer-events-auto': indexBanner == i,
          }"
        >
          <picture>
            <source
              media="(orientation: portrait)"
              :srcset="x.photoPortrait | assetURL"
            >
            <img
              :alt="x.name"
              :src="x.photoLandscape | assetURL"
              class="aspect-ratio-content object-fit-cover"
            >
          </picture>

          <div
            :class="{
              'opacity-0': indexBanner != i,
              'opacity-100': indexBanner == i,
            }"
            v-html="itemsBanner[indexBanner].html"
          />
        </nuxt-link>
        <a
          v-else
          :key="i + 'external'"
          :href="x.url"
          target="_blank"
          class="transition-05s"
          :class="{
            'opacity-0 pointer-events-none': indexBanner != i,
            'opacity-100 pointer-events-auto': indexBanner == i,
          }"
        >
          <picture>
            <source
              media="(orientation: portrait)"
              :srcset="x.photoPortrait | assetURL"
            >
            <img
              :alt="x.name"
              :src="x.photoLandscape | assetURL"
              class="aspect-ratio-content object-fit-cover"
            >
          </picture>

          <div
            :class="{
              'opacity-0': indexBanner != i,
              'opacity-100': indexBanner == i,
            }"
            v-html="itemsBanner[indexBanner].html"
          />
        </a>
      </template>

      <div
        class="absolute w-full nowrap hidden-scroll overflow-auto text-center pt-3 px-5 lg:pb-3"
        style="bottom: 2%"
      >
        <div
          v-for="(x, i) of itemsBanner"
          :key="i"
          class="inline-block rounded-10 bg-white p-1 mx-2 lg:mx-3 pointer border-yada haohao"
          :class="{ 'opacity-50': indexBanner != i }"
          style="line-height: 0"
          @click="changeBanner(i)"
        >
          <!-- <img
            :alt="x.name"
            :src="x.photo | assetURL"
            class="h-14 w-14 lg:h-20 lg:w-20"
          > -->
          <img
            :alt="x.name"
            :src="x.photo | assetURL"
            class="h-14 w-14 lg:h-20 lg:w-20"
          >
        </div>
      </div>
    </header>
    
    
    <!--
    .########..########...#######..##.....##..#######..########.####..#######..##....##
    .##.....##.##.....##.##.....##.###...###.##.....##....##.....##..##.....##.###...##
    .##.....##.##.....##.##.....##.####.####.##.....##....##.....##..##.....##.####..##
    .########..########..##.....##.##.###.##.##.....##....##.....##..##.....##.##.##.##
    .##........##...##...##.....##.##.....##.##.....##....##.....##..##.....##.##..####
    .##........##....##..##.....##.##.....##.##.....##....##.....##..##.....##.##...###
    .##........##.....##..#######..##.....##..#######.....##....####..#######..##....##
    -->
    <section class="mt-10 lg:mt-10 mb-10">
      <div class="container max-w-1200">
        <div class="mb-2">
          <div class="text-20 mb=0 lg:mb-5">
            限定促銷
          </div>
        </div>
        <!--
        .########...#######..########..########.########.....###....##x##.########
        .##.....##.##.....##.##.....##....##....##.....##...##.##....##.....##...
        .##.....##.##.....##.##.....##....##....##.....##..##...##...##.....##...
        .########..##.....##.########.....##....########..##.....##..##.....##...
        .##........##.....##.##...##......##....##...##...#########..##.....##...
        .##........##.....##.##....##.....##....##....##..##.....##..##.....##...
        .##.........#######..##.....##....##....##.....##.##.....##.####....##...
        -->
        <div class="lg:hidden">
          <!-- <div class=""> -->
          <div class="w-full">
            <no-ssr>
              <splide
                v-if="isReady"
                :options="options"
              >
                <splide-slide
                  v-for="(x, i) of itemsCarouselLimitPromotion"
                  :key="i"
                >
                  <div
                    v-for="(xx, ii) of x"
                    :key="ii"
                    class="mb-3 mt-4"
                    @click="onClickProduct(xx, ii)"
                  >
                    <nuxt-link
                      :to="'/products/' + xx.id + '/' + xx.name.replace(/\//g, '-')"
                    >
                      <div
                        class="border-1 rounded-10 overflow-hidden border-eee haohao"
                      >
                        <div class="flex items-start">
                          <div class="relative bg-f9f9f9">
                            <div
                              style="padding-bottom: calc(100% / 3 * 3)"
                              class="w-30"
                            />
                            <picture>
                              <img
                                :src="xx.photo | assetURL"
                                class="aspect-ratio-content object-fit-cover"
                              >
                            </picture>
                            <div
                              v-if="xx.isReplenishment || xx.stock <= 0"
                              class="px-5 absolute w-full"
                              style="left: 0; top: calc(50% - 20px)"
                            >
                              <div
                                class="bg-white border-yada text-center w-full py-2 rounded-5 opacity-90"
                              >
                                補貨中
                              </div>
                            </div>
                          </div>
                          <div class="ml-3 flex-auto pr-3">
                            <div class="mt-2">
                              <div class="mb-6">
                                <div
                                  class="mb-1 text-left h-12 overflow-hidden"
                                >
                                  <span>{{ xx.name }}</span>
                                  <span class="text-999 ml-1">
                                    {{ xx.contain }}
                                  </span>
                                </div>
                              </div>
                              <div class="flex justify-end items-center">
                                <div
                                  v-if="xx.priceTemp > xx.price"
                                  class="line-through mr-5 text-999"
                                >
                                  <span class="text-14">
                                    $
                                  </span>
                                  {{ xx.priceTemp | number }}
                                </div>

                                <div class="text-red text-16">
                                  <span class="text-14">
                                    $
                                  </span>
                                  {{ xx.price | number }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </nuxt-link>
                  </div>
                </splide-slide>
              </splide>
            </no-ssr>
          </div>
        </div>

        <!--
        .##..........###....##....##.########...######...######.....###....########..########
        .##.........##.##...###...##.##.....##.##....##.##....##...##.##...##.....##.##......
        .##........##...##..####..##.##.....##.##.......##........##...##..##.....##.##......
        .##.......##.....##.##.##.##.##.....##..######..##.......##.....##.########..######..
        .##.......#########.##..####.##.....##.......##.##.......#########.##........##......
        .##.......##.....##.##...###.##.....##.##....##.##....##.##.....##.##........##......
        .########.##.....##.##....##.########...######...######..##.....##.##........########
        -->
        <div class="hidden lg:block">
          <div class="row list-reset -mx-3">
            <div
              v-for="(x, i) of itemsLimitPromotion"
              :key="i"
              class="flex flex-col px-3 w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mb-6"
              @click="onClickProduct(x, i)"
            >
              <nuxt-link
                :to="'/products/' + x.id + '/' + x.name.replace(/\//g, '-')"
              >
                <div
                  class="border-1 rounded-10 overflow-hidden border-eee haohao"
                >
                  <div class="flex items-start">
                    <div class="relative bg-f9f9f9">
                      <div
                        style="padding-bottom: calc(100% / 3 * 3)"
                        class="w-30"
                      />
                      <picture>
                        <img
                          alt
                          :src="x.photo | assetURL"
                          class="aspect-ratio-content object-fit-cover"
                        >
                      </picture>
                      <div
                        v-if="x.isReplenishment || x.stock <= 0"
                        class="px-5 absolute w-full"
                        style="left: 0; top: calc(50% - 20px)"
                      >
                        <div
                          class="bg-white border-yada text-center w-full py-2 rounded-5 opacity-90"
                        >
                          補貨中
                        </div>
                      </div>
                    </div>

                    <div class="ml-3 flex-auto pr-3">
                      <div class="mt-2">
                        <div class="mb-8">
                          <div class="mb-1 h-12 overflow-hidden">
                            <span>{{ x.name }}</span>
                            <span class="text-999 ml-1">
                              {{ x.contain }}
                            </span>
                          </div>
                        </div>
                        <div class="flex justify-end items-center">
                          <div
                            v-if="x.priceTemp > x.price"
                            class="line-through mr-4 text-16 text-999"
                          >
                            <span class="text-14">
                              $
                            </span>
                            {{ x.priceTemp | number }}
                          </div>
                          <div class="text-red text-16">
                            <span class="text-14">
                              $
                            </span>
                            {{ x.price | number }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container max-w-1200">
      <hr>
    </section>

    <!--
    ..######..##.....##.########...######...######..########..####.########..########
    .##....##.##.....##.##.....##.##....##.##....##.##.....##..##..##.....##.##......
    .##.......##.....##.##.....##.##.......##.......##.....##..##..##.....##.##......
    ..######..##.....##.########...######..##.......########...##..########..######..
    .......##.##.....##.##.....##.......##.##.......##...##....##..##.....##.##......
    .##....##.##.....##.##.....##.##....##.##....##.##....##...##..##.....##.##......
    ..######...#######..########...######...######..##.....##.####.########..########
    -->
    <section class=" ">
      <div class="h-4" />
      <div class="h-4" />
      <div class="h-4 lg:hidden" />
      <div class="container max-w-1200">
        <nuxt-link to="/order-subscribe/index">
          <!-- portrait -->
          <img
            src="https://d1jjjfa1mlyr2v.cloudfront.net/c/home/portrait-02.png"
            class="w-full lg:hidden"
          >
          <!-- landscape -->
          <img
            src="https://d1jjjfa1mlyr2v.cloudfront.net/c/home/landscape-02.png"
            class="w-full hidden lg:block"
          >
        </nuxt-link>
      </div>
    </section>

    <!--
    .########.##.....##.########.##....##.########
    .##.......##.....##.##.......###...##....##...
    .##.......##.....##.##.......####..##....##...
    .######...##.....##.######...##.##.##....##...
    .##........##...##..##.......##..####....##...
    .##.........##.##...##.......##...###....##...
    .########....###....########.##....##....##...
    -->
    <section class="mt-16 lg:mt-24">
      <div class="container max-w-1200">
        <div class="mb-1">
          <div class="text-20">
            活動特輯
          </div>
        </div>

        <div
          v-if="isReady"
          class="lg:hidden"
        >
          <no-ssr>
            <splide :options="options">
              <splide-slide
                v-for="(x, i) of itemsEvent"
                :key="i"
              >
                <nuxt-link :to="x.url">
                  <div
                    class="border-1 rounded-10 border-eee overflow-hidden haohao"
                  >
                    <div class="relative">
                      <div
                        style="padding-bottom: calc(100% / 300 * 200)"
                        class="w-full"
                      />
                      <picture>
                        <img
                          alt
                          :src="x.photo | assetURL"
                          class="aspect-ratio-content object-fit-cover bg-f9f9f9"
                        >
                      </picture>
                    </div>
                    <div class="px-3 pt-2 pb-1">
                      <div
                        class="line-champ-1-2 w-full text-left"
                        style="-webkit-box-orient: vertical"
                      >
                        {{ x.name }}
                      </div>
                    </div>
                  </div>
                </nuxt-link>
              </splide-slide>
            </splide>
          </no-ssr>
        </div>

        <!-- landscape only -->
        <div class="-mx-2 lg:-mx-3 hidden lg:block">
          <div
            ref="carouselPromotion"
            class="carousel-home text-center"
          >
            <div
              v-for="(x, i) of itemsEvent"
              :key="i"
              class="flex flex-col px-2 lg:px-3 w-4/5 lg:w-1/4 mb-5 mt-4"
            >
              <nuxt-link :to="x.url">
                <div
                  class="border-1 rounded-10 border-eee overflow-hidden haohao"
                >
                  <div class="relative">
                    <div
                      style="padding-bottom: calc(100% / 300 * 200)"
                      class="w-full"
                    />
                    <picture>
                      <img
                        alt
                        :src="x.photo | assetURL"
                        class="aspect-ratio-content object-fit-cover bg-f9f9f9"
                      >
                    </picture>
                  </div>
                  <div class="px-3 pt-2 pb-1">
                    <div
                      class="line-champ-1-2 w-full text-left"
                      style="-webkit-box-orient: vertical"
                    >
                      {{ x.name }}
                    </div>
                  </div>
                </div>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="mt-16 lg:mt-24 hidden">
      <div class="container max-w-1200">
        <div class="mb-1">
          <div class="text-2">
            活動特輯
          </div>
        </div>

        <div class="relative">
          <div
            ref="carouselPromotion123"
            class="-mx-2 lg:-mx-3 hidden-scroll overflow-auto"
          >
            <div class="text-center nowrap w-full">
              <div
                v-for="(x, i) of itemsEvent"
                :key="i"
                class="inline-block px-2 lg:px-3 w-4/5 lg:w-1/4 mb-5 mt-4"
              >
                <nuxt-link :to="x.url">
                  <div
                    class="border-1 rounded-10 border-eee overflow-hidden haohao"
                  >
                    <div class="relative">
                      <div
                        style="padding-bottom: calc(100% / 300 * 200)"
                        class="w-full"
                      />
                      <picture>
                        <img
                          alt
                          :src="x.photo | assetURL"
                          class="aspect-ratio-content object-fit-cover bg-f9f9f9"
                        >
                      </picture>
                    </div>
                    <div class="px-3 pt-2 pb-1">
                      <div
                        class="line-champ-1-2 w-full text-left"
                        style="-webkit-box-orient: vertical"
                      >
                        {{ x.name }}
                      </div>
                    </div>
                  </div>
                </nuxt-link>
              </div>
            </div>
          </div>
          <!-- arrow -->
          <div
            class="absolute bg-blue text-white px-3 py-1 hidden lg:block"
            style="left: -80px; top: 50%"
            @click="moveLeft()"
          >
            left
          </div>

          <div
            class="absolute bg-blue text-white px-3 py-1 hidden lg:block"
            style="right: -80px; top: 50%"
            @click="moveRight()"
          >
            right
          </div>
        </div>
      </div>
    </section>

    <!--
    .##....##.########.##......##..######.
    .###...##.##.......##..##..##.##....##
    .####..##.##.......##..##..##.##......
    .##.##.##.######...##..##..##..######.
    .##..####.##.......##..##..##.......##
    .##...###.##.......##..##..##.##....##
    .##....##.########..###..###...######.
    -->
    <section class="mt-10 lg:mt-10">
      <div class="container max-w-1200">
        <div class="mb-1">
          <div class="text-20">
            新品發表
          </div>
        </div>
        <!-- portrai only -->
        <div class="lg:hidden mb-5">
          <no-ssr>
            <splide
              v-if="isReady"
              :options="options"
            >
              <splide-slide
                v-for="(x, i) of itemsNews"
                :key="i"
              >
                <nuxt-link
                  :to="x.url"
                >
                  <div
                    class="border-1 rounded-10 border-eee overflow-hidden haohao mb-2"
                    @click="onClickNews(x, i)"
                  >
                    <div class="relative">
                      <div style="padding-bottom: calc(100% / 4 * 2.6)" />
                      <picture>
                        <img
                          alt
                          :src="x.photo | assetURL"
                          class="aspect-ratio-content object-fit-cover bg-f9f9f9"
                        >
                      </picture>
                      <div
                        v-if="x.isNew"
                        class="absolute text-white bg-333 px-1 py-0 rounded-5"
                        style="top: 15px; right: 15px"
                      >
                        New
                      </div>
                    </div>
                    <div class="flex justify-between px-3 pt-2 pb-1">
                      <div
                        class="text-14 mb-1 line-champ-1-2 h-6 w-full text-left"
                        style="-webkit-box-orient: vertical"
                      >
                        {{ x.name }}
                      </div>
                    </div>
                  </div>
                </nuxt-link>
              </splide-slide>
            </splide>
          </no-ssr>
        </div>

        <!-- landscape only -->
        <div class="-mx-2 lg:-mx-3 hidden lg:block">
          <div
            ref="carouselNews"
            class="carousel-home text-center"
          >
            <div
              v-for="(x, i) of itemsNews"
              :key="i"
              class="flex flex-col px-2 lg:px-3 w-4/5 lg:w-1/4 mb-5 mt-4"
              @click="onClickNews(x, i)"
            >
              <nuxt-link :to="x.url">
                <div
                  class="border-1 rounded-10 border-eee overflow-hidden haohao"
                >
                  <div class="relative">
                    <div style="padding-bottom: calc(100% / 4 * 2.6)" />
                    <picture>
                      <img
                        alt
                        :src="x.photo | assetURL"
                        class="aspect-ratio-content object-fit-cover bg-f9f9f9"
                      >
                    </picture>
                    <div
                      v-if="x.isNew"
                      class="absolute text-white bg-333 px-1 py-0 rounded-5"
                      style="top: 15px; right: 15px"
                    >
                      New
                    </div>
                  </div>
                  <div class="flex justify-between px-3 pt-2 pb-1">
                    <div
                      class="text-14 mb-1 line-champ-1-2 h-6 w-full text-left"
                      style="-webkit-box-orient: vertical"
                    >
                      {{ x.name }}
                    </div>
                  </div>
                </div>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    

    <!--
    ....###....########..########.####..######..##.......########
    ...##.##...##.....##....##.....##..##....##.##.......##......
    ..##...##..##.....##....##.....##..##.......##.......##......
    .##.....##.########.....##.....##..##.......##.......######..
    .#########.##...##......##.....##..##.......##.......##......
    .##.....##.##....##.....##.....##..##....##.##.......##......
    .##.....##.##.....##....##....####..######..########.########
    -->
    <section class="mt-10">
      <div class="container max-w-1200">
        <div class="flex mb-1 justify-between items-center">
          <div class="text-20 nowrap">
            文章特集
          </div>
          <div>
            <nuxt-link
              class="text-999 text-14"
              to="/article/listing"
            >
              查看全部文章
            </nuxt-link>
          </div>
        </div>

        <!-- portrait only -->
        <div class="lg:hidden ">
          <no-ssr>
            <splide
              v-if="isReady"
              :options="options2"
            >
              <splide-slide
                v-for="(x, i) of itemsArticle"
                :key="i"
              >
                <!-- <nuxt-link :to="'/article/item/' + x.id"> -->
                <nuxt-link :to="'/article/item/' + x.url">
                  <div
                    class="border-1 rounded-10 border-eee overflow-hidden haohao mb-3"
                  >
                    <div class="relative">
                      <div style="padding-bottom: calc(100% / 4 * 2.8)" />
                      <picture>
                        <img
                          alt
                          :src="x.photo | assetURL"
                          class="aspect-ratio-content object-fit-cover bg-f9f9f9"
                        >
                      </picture>
                    </div>
                    <div class="px-3 py-3 text-left">
                      <div
                        class="text-14 mb-1 line-champ-1-2 h-6 w-full"
                        style="-webkit-box-orient: vertical"
                      >
                        {{ x.name }}
                      </div>

                      <div class="flex text-12 justify-between text-999">
                        <div>{{ x.date | dateSlash }}</div>
                        <div>{{ typeText('ARTICLE_TYPE', x.typeID) }}</div>
                      </div>
                    </div>
                  </div>
                </nuxt-link>
              </splide-slide>
            </splide>
          </no-ssr>
        </div>

        <!-- landscape only -->
        <div class="-mx-2 lg:-mx-3 hidden lg:block">
          <div
            ref="carouselArticle"
            class="carousel-home hide-dots text-center"
          >
            <div
              v-for="(x, i) of itemsArticle"
              :key="i"
              class="flex flex-col px-2 lg:px-3 w-4/5 lg:w-1/4 mb-5 mt-4"
            >
              <nuxt-link :to="'/article/item/' + x.url">
                <div
                  class="border-1 rounded-10 border-eee overflow-hidden haohao"
                >
                  <div class="relative">
                    <div style="padding-bottom: calc(100% / 4 * 2.8)" />
                    <picture>
                      <img
                        alt
                        :src="x.photo | assetURL"
                        class="aspect-ratio-content object-fit-cover bg-f9f9f9"
                      >
                    </picture>
                  </div>
                  <div class="px-3 py-3 text-left">
                    <div
                      class="text-14 mb-1 line-champ-1-2 h-6 w-full"
                      style="-webkit-box-orient: vertical"
                    >
                      {{ x.name }}
                    </div>

                    <div class="flex text-12 justify-between text-999">
                      <div>{{ x.date | dateSlash }}</div>
                      <div>{{ typeText('ARTICLE_TYPE', x.typeID) }}</div>
                    </div>
                  </div>
                </div>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--
    ....###....########...#######..##.....##.########
    ...##.##...##.....##.##.....##.##.....##....##...
    ..##...##..##.....##.##.....##.##.....##....##...
    .##.....##.########..##.....##.##.....##....##...
    .#########.##.....##.##.....##.##.....##....##...
    .##.....##.##.....##.##.....##.##.....##....##...
    .##.....##.########...#######...#######.....##...
    -->
    <section class="mt-10 mb-10">
      <div class="container max-w-1200">
        <div class="text-20 nowrap mb-5">
          關於我們
        </div>
        <div class="row list-reset -mx-2 lg:-mx-3">
          <!-- <div class="flex flex-col px-2 lg:px-3 w-1/2 lg:w-1/4 mb-4 lg:mb-0">
            <nuxt-link to="/about/enterprise">
              <div
                class="border-1 rounded-10 border-eee overflow-hidden haohao"
              >
                <div class="flex items-center flex-wrap">
                  <div
                    class="bg-f9f9f9 py-1 lg:py-0 w-full lg:w-auto text-center w-24"
                  >
                    <img
                      alt
                      src="https://d1jjjfa1mlyr2v.cloudfront.net/c/assets/icon-01.png"
                      class="h-24 w-24 inline-block"
                    >
                  </div>
                  <div
                    class="flex-none lg:flex-1 ml-0 lg:ml-3 text-center lg:text-left w-full lg:w-auto py-3"
                  >
                    企業資訊
                  </div>
                </div>
              </div>
            </nuxt-link>
          </div> -->
          <div class="flex flex-col px-2 lg:px-3 w-1/2 lg:w-1/3 mb-4 lg:mb-0">
            <nuxt-link to="/shop/index">
              <div
                class="border-1 rounded-10 border-eee overflow-hidden haohao"
              >
                <div class="flex items-center flex-wrap">
                  <div
                    class="bg-f9f9f9 py-1 lg:py-0 w-full lg:w-auto text-center w-24"
                  >
                    <img
                      alt
                      src="https://d1jjjfa1mlyr2v.cloudfront.net/c/assets/icon-02.png"
                      class="h-24 w-24 inline-block"
                    >
                  </div>
                  <div
                    class="flex-none lg:flex-1 ml-0 lg:ml-3 text-center lg:text-left w-full lg:w-auto py-3"
                  >
                    實體販售地點
                  </div>
                </div>
              </div>
            </nuxt-link>
          </div>
          <div class="flex flex-col px-2 lg:px-3 w-1/2 lg:w-1/3 mb-4 lg:mb-0">
            <nuxt-link to="/jobs">
              <div
                class="border-1 rounded-10 border-eee overflow-hidden haohao"
              >
                <div class="flex items-center flex-wrap">
                  <div
                    class="bg-f9f9f9 py-1 lg:py-0 w-full lg:w-auto text-center w-24"
                  >
                    <img
                      alt
                      src="https://d1jjjfa1mlyr2v.cloudfront.net/c/assets/icon-03.png"
                      class="h-24 w-24 inline-block"
                    >
                  </div>
                  <div
                    class="flex-none lg:flex-1 ml-0 lg:ml-3 text-center lg:text-left w-full lg:w-auto py-3"
                  >
                    人才招募
                  </div>
                </div>
              </div>
            </nuxt-link>
          </div>

          <div class="flex flex-col px-2 lg:px-3 w-1/2 lg:w-1/3 mb-4 lg:mb-0">
            <nuxt-link to="/about/service">
              <div
                class="border-1 rounded-10 border-eee overflow-hidden haohao"
              >
                <div class="flex items-center flex-wrap">
                  <div
                    class="bg-f9f9f9 py-1 lg:py-0 w-full lg:w-auto text-center w-24"
                  >
                    <img
                      alt
                      src="https://d1jjjfa1mlyr2v.cloudfront.net/c/assets/icon-04.png"
                      class="h-24 w-24 inline-block"
                    >
                  </div>
                  <div
                    class="flex-none lg:flex-1 ml-0 lg:ml-3 text-center lg:text-left w-full lg:w-auto py-3"
                  >
                    支援服務
                  </div>
                </div>
              </div>
            </nuxt-link>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
let Flickity;
// import axios from 'axios';

if (process.client) {
  Flickity = require('flickity');
}

export default {
  head() {
    /** {@link https://developers.google.com/search/docs/data-types/sitelinks-searchbox } */
    const structuredData = [
      {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        url: `${process.env.CANONICAL_ORIGIN}`,
        potentialAction: [
          {
            '@type': 'SearchAction',
            target: `${
              process.env.CANONICAL_ORIGIN
            }/search/{search_term_string}`,
            'query-input': 'required name=search_term_string',
          },
        ],
      },
      {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: process.env.CANONICAL_TITLE,
        alternateName: '有理百物',
        logo:
          'https://d1jjjfa1mlyr2v.cloudfront.net/c/assets/logo-unipapa-client.png',
        url: process.env.CANONICAL_ORIGIN,
        sameAs: [
          'https://www.facebook.com/unipapa',
          'https://page.line.me/unipapa',
          'https://medium.com/unipapa',
          'https://www.instagram.com/unipapa_official/',
          'https://www.youtube.com/channel/UCkHtNRpcSDHpTkeJ7voWdzg',
        ],
      },
    ];

    return {
      __dangerouslyDisableSanitizers: ['script'],

      meta: [{ property: 'og:url', content: process.env.CANONICAL_ORIGIN }],

      link: [{ rel: 'canonical', href: process.env.CANONICAL_ORIGIN }],

      script: [
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify(structuredData),
        },
      ],
    };
  },

  name: 'Index',

  /* 
  async asyncData({ query }) {
    let result = {};

    let itemsNews = [];
    let itemsNewsProduct = [];
    let itemsEvent = [];
    let itemsArticle = [];
    let itemsLimitPromotion = [];
    let itemsCarouselLimitPromotion = [];
    let itemsBanner = [];

    try {
      let isPreview = false;
      if (query.isPreview && query.isPreview == '1') {
        isPreview = true;
      }

      result = await axios.post(
        `${process.env.API_ORIGIN}/listing/getListing`,
        { _model: 'index', isPreview }
      );

      itemsNews = result.data.data.extend.news;
      itemsNewsProduct = result.data.data.extend.newsProduct;
      itemsEvent = result.data.data.extend.event;
      itemsArticle = result.data.data.extend.article;
      itemsLimitPromotion = result.data.data.extend.limitPromotion;
      itemsBanner = result.data.data.extend.banner;

      var temp = [];
      for (const i in itemsLimitPromotion) {
        const x = itemsLimitPromotion[i];
        temp.push(x);
        if (temp.length > 3) {
          itemsCarouselLimitPromotion.push(temp);
          temp = [];
        }
      }
    } catch (e) {
      // yada
    }

    return {
      itemsNews,
      itemsNewsProduct,
      itemsEvent,
      itemsArticle,
      itemsLimitPromotion,
      itemsCarouselLimitPromotion,
      itemsBanner,
    };
  }, */

  data() {
    return {
      isReady: false,
      // isReady: true,
      options: {
        pagination: true,
        type: 'loop',
        arrows: false,
        perPage: 1,
        gap: '10px',
        fixedWidth: '80%',
      },
      options2: {
        pagination: false,
        type: 'loop',
        arrows: false,
        perPage: 1,
        gap: '10px',
        fixedWidth: '80%',
      },

      isShowVideo: false,
      carousel0: null,
      products: [],
      itemsNews: [],
      itemsNewsProduct: [],
      itemsEvent: [],
      itemsArticle: [],
      itemsLimitPromotion: [],
      itemsLimitPurchase: [],
      itemsCarouselLimitPromotion: [],
      itemsBanner: [],

      $carouselNews: null,
      $carouselPromotion: null,
      $carouselArticle: null,
      $carouselLimitPromotion: null,

      indexBanner: 0,
      banners: [],
    };
  },

  async mounted() {
    this.initMounted();
  },

  methods: {
    initMounted() {
      const self = this;
      setTimeout(async () => {
        await self.getData();

        setTimeout(() => {
          self.isReady = true;
        }, 20);

        setTimeout(() => {
          self.initFlickity();
        }, 100);

        setTimeout(() => {
          let datalayerProducts = [];

          for (const i in self.itemsLimitPromotion) {
            const x = self.itemsLimitPromotion[i];

            let category = '--';
            try {
              category = self.typeText('PRODUCT_TYPE', x.newTypeIDs[0]);
            } catch (e) {
              // yada
            }

            let brandName = '--';
            try {
              brandName = x.brand.name;
            } catch (e) {
              // yada
            }

            const datalayerProduct = {
              id: x.id,
              name: x.nameFull,
              category: category,
              price: x.price,
              brand: brandName,
              variant: '',
              position: i + 1,
              list: '首頁商品清單',
            };
            datalayerProducts.push(datalayerProduct);
          }

          // Product Impression
          window.dataLayer.push({
            impression: {
              products: datalayerProducts,
            },
            event: 'td_impressionParse',
          });
        }, 100);
      }, 20);
    },

    initFlickity() {
      try {
        this.$carouselPromotion = new Flickity(this.$refs.carouselPromotion, {
          contain: true,
          pageDots: true,
          cellAlign: 'left',
          prevNextButtons: false,
        });

        this.$carouselNews = new Flickity(this.$refs.carouselNews, {
          pageDots: true,
          cellAlign: 'left',
          prevNextButtons: false,
          wrapAround: true,
        });

        this.$carouselArticle = new Flickity(this.$refs.carouselArticle, {
          contain: true,
          pageDots: true,
          cellAlign: 'left',
          prevNextButtons: false,
          wrapAround: true,
        });
      } catch (e) {
        // yada
      }
    },

    async getData() {
      let result = {};

      let itemsNews = [];
      let itemsNewsProduct = [];
      let itemsEvent = [];
      let itemsArticle = [];
      let itemsLimitPromotion = [];
      let itemsCarouselLimitPromotion = [];
      let itemsBanner = [];

      try {
        const query = this.$route.query;
        let isPreview = false;
        if (query.isPreview && query.isPreview == '1') {
          isPreview = true;
        }

        result = await this.post(`listing/getListing`, {
          _model: 'index',
          isPreview,
        });

        itemsNews = result.data.data.extend.news;
        itemsNewsProduct = result.data.data.extend.newsProduct;
        itemsEvent = result.data.data.extend.event;
        itemsArticle = result.data.data.extend.article;
        itemsLimitPromotion = result.data.data.extend.limitPromotion;
        itemsBanner = result.data.data.extend.banner;

        var temp = [];
        for (const i in itemsLimitPromotion) {
          const x = itemsLimitPromotion[i];
          temp.push(x);
          if (temp.length > 3) {
            itemsCarouselLimitPromotion.push(temp);
            temp = [];
          }
        }
      } catch (e) {
        // yada
      }

      this.itemsNews = itemsNews;
      this.itemsNewsProduct = itemsNewsProduct;
      this.itemsEvent = itemsEvent;
      this.itemsArticle = itemsArticle;
      this.itemsLimitPromotion = itemsLimitPromotion;
      this.itemsCarouselLimitPromotion = itemsCarouselLimitPromotion;
      this.itemsBanner = itemsBanner;
    },

    onClickNews(item, index) {
      var q = this.itemsNewsProduct.find(z => z.id == item.productID);
      if (q) {
        // push datalayer
        // let productName = q.name;
        // if (q.contain) {
        //   productName += ' ' + q.contain;
        // }

        let category = '--';
        try {
          category = this.typeText('PRODUCT_TYPE', q.newTypeIDs[0]);
        } catch (e) {
          // yada
        }

        window.dataLayer.push({
          ecommerce: {
            click: {
              products: [
                {
                  id: q.id,
                  name: q.nameFull,
                  category: category,
                  price: q.price,
                  brand: q.brandName,
                  variant: '',
                  position: index + 1,
                  list: '首頁新表發表清單',
                },
              ],
            },
          },
          event: 'td_productClick',
        });
      }
    },

    onClickProduct(q, index) {
      // push datalayer
      // let productName = q.name;
      // if (q.contain) {
      //   productName += ' ' + q.contain;
      // }

      let category = '--';
      try {
        category = this.typeText('PRODUCT_TYPE', q.newTypeIDs[0]);
      } catch (e) {
        // yada
      }

      window.dataLayer.push({
        ecommerce: {
          click: {
            products: [
              {
                id: q.id,
                name: q.nameFull,
                category: category,
                price: q.price,
                brand: q.brandName,
                variant: '',
                position: index + 1,
                list: '首頁限定促銷清單',
              },
            ],
          },
        },
        event: 'td_productClick',
      });
    },

    moveLeft() {
      this.$refs.carouselPromotion123.scrollLeft -= 100;
    },

    moveRight() {
      this.$refs.carouselPromotion123.scrollLeft += 100;
    },

    changeBanner(q) {
      this.indexBanner = q;
    },
  },
};
</script>
